import '@hotwired/turbo-rails';

Turbo.setProgressBarDelay(500);

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

Turbo.setConfirmMethod((message) => {
  let dialog = document.getElementById('turbo-confirm');
  dialog.querySelector('p').textContent = message;
  dialog.showModal();

  return new Promise((resolve) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue == 'confirm');
    }, { once: true });

    dialog.addEventListener('click', (event) => {
      if (event.target.nodeName === 'DIALOG') {
        dialog.returnValue = 'cancel';
        dialog.close();
      }
    });
  });
});

document.addEventListener('turbo:before-render', (event) => {
  if (document.startViewTransition) {
    event.preventDefault();
    document.startViewTransition(() => {
      event.detail.resume();
    });
  }
});

document.addEventListener('turbo:before-frame-render', (event) => {
  if (document.startViewTransition) {
    event.preventDefault();
    document.startViewTransition(() => {
      event.detail.resume();
    });
  }
});

// FIXME If turbo content missing then visit the url
// document.addEventListener('turbo:frame-missing', (event) => {
//   const { detail: { response, visit } } = event;
//   event.preventDefault();
//   visit(response.url);
// });
